import React, { useRef, useEffect } from "react";
import WebViewer from "@pdftron/webviewer";
import "./App.css";

const App = () => {
  const viewer = useRef(null);

  // if using a class, equivalent of componentDidMount
  useEffect(() => {
    WebViewer(
      {
        path: "/webviewer/lib",
        initialDoc: "/files/DOCUMENT-SOPHIE.pdf",
      },
      viewer.current
    ).then((instance) => {
      const { documentViewer, annotationManager, Annotations } = instance.Core;

      instance.UI.setLanguage("fr");
      instance.UI.setPrintQuality(2);
      instance.UI.useEmbeddedPrint(true);
      instance.UI.disableElements(["toolbarGroup-Shapes"]);
      instance.UI.disableElements(["toolbarGroup-Edit"]);
      instance.UI.disableElements(["toolbarGroup-Insert"]);
      instance.UI.disableElements(["toolbarGroup-Annotate"]);
      instance.UI.disableElements(["toolbarGroup-Forms"]);
      instance.UI.disableElements(["toolbarGroup-FillAndSign"]);
      instance.UI.disableElements(["toolbarGroup-View"]);
      instance.UI.disableElements(["searchButton"]);
      instance.UI.disableElements(["toggleNotesButton"]);
      instance.UI.disableElements(["viewControlsButton"]);
      instance.UI.disableElements(["toolsHeader"]);
      instance.UI.setHeaderItems((header) => {
        header.push({
          type: "actionButton",
          img:
            '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm-5 16c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-10H5V5h10v4z"/></svg>',
          onClick: async () => {
            const doc = documentViewer.getDocument();
            const xfdfString = await annotationManager.exportAnnotations();
            const data = await doc.getFileData({
              // saves the document with annotations in it
              xfdfString,
            });
            const arr = new Uint8Array(data);
            const blob = new Blob([arr], { type: "application/pdf" });

            // Add code for handling Blob here
            const url = URL.createObjectURL(blob);
            window.open(url);
          },
        });
      });
    });
  }, []);

  return (
    <div className="App">
      <div className="header">
        <span>
          <strong>RGPD - </strong>Esthétipédiform
        </span>
      </div>
      <div className="webviewer" ref={viewer}></div>
      <div
        className="header"
      >
        <img
          alt="Logo Esthétipédiform"
          style={{ width: 30, marginTop: 10 }}
          src="https://esthetipediform.com/wp-content/uploads//2022/01/Fichier-6.svg"
        ></img>
      </div>
    </div>
  );
};

export default App;
